<template>
  <skin-color-template
    :gender="gender"
    :skin-color-allowed-values="skinColorAllowedValues"
    :skin-color="skinColor"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import SkinColorTemplate from '@/modules/questionnaire/components/steps/common/identity/skin-color/SkinColorTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import {
  SKIN_COLOR_WITH_MIDDLE_HAIR_VALUES,
  SKIN_COLOR_WITH_LIGHT_HAIR_VALUES,
  SKIN_COLOR_WITH_DARK_HAIR_VALUES,
  DARK_HAIR_COLOR,
  LIGHT_HAIR_COLOR
} from '@/modules/questionnaire/components/steps/common/identity/skin-color/skinColorValues';

const fieldsToReset = ['skinType'];

const { field, requiredField } = fieldBuilder;

const FIELDS = [
  field('gender'),
  field('naturalHairColor'),
  requiredField('skinColor'),
  field('skinType')
];

export default {
  name: 'SkinColor',
  components: {
    SkinColorTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  computed: {
    skinColorAllowedValues() {
      if (DARK_HAIR_COLOR.includes(this.naturalHairColor)) {
        return SKIN_COLOR_WITH_DARK_HAIR_VALUES;
      }

      if (LIGHT_HAIR_COLOR.includes(this.naturalHairColor)) {
        return SKIN_COLOR_WITH_LIGHT_HAIR_VALUES;
      }

      return SKIN_COLOR_WITH_MIDDLE_HAIR_VALUES;
    }
  },
  watch: {
    skinColor() {
      this.resetStoreFieldsByName(fieldsToReset);

      this.showNextStep();
    }
  }
};
</script>
